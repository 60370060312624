import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import "../../../css/custom-css/custom-products-services.css"

import Layout from "../../../components/custom-components/Layout"
import BannerHeader from "../../../components/custom-components/BannerHeader"

// import bg from "../../../images/products-and-services/header-cmo.webp"
import bg from "../../../images/products-and-services/header-diagnostics.jpg"
import Seo from "../../../components/seo"

export const query = graphql`
  {
    image1: contentfulServices(title: { eq: "diagnostic-1" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
    image2: contentfulServices(title: { eq: "diagnostic-2" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
    image3: contentfulServices(title: { eq: "diagnostic-3" }) {
      image {
        gatsbyImageData(layout: FIXED, height: 200, width: 300)
      }
    }
  }
`

const Diagnostics = () => {
  const data = useStaticQuery(query)
  const image1 = data.image1
  const image2 = data.image2
  const image3 = data.image3
  return (
    <Layout>
      <Seo title="Novel Diagnostics" />

      <BannerHeader background={bg} title="Novel Diagnostics" servicesDetails />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div>
              <Link href="/products-and-services">
                <div className="icon-content">
                  <h5 className="dlab-title">
                    <span className="icon-sm">
                      <i className="ti-arrow-left"></i>
                    </span>
                    Back
                  </h5>
                </div>
              </Link>
            </div>

            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="active">
                      <Link to="/products-and-services/services/novel-diagnostics">
                        Novel Diagnostics
                      </Link>
                    </li>
                    <li>
                      <Link to="/products-and-services/services/therapeutics">
                        Therapeutics
                      </Link>
                    </li>
                    <li>
                      <Link to="/products-and-services/services/other-services">
                        Other Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-md-4 col-sm-12 mb-4">
                    <GatsbyImage image={image1.image.gatsbyImageData} />
                  </div>
                  <div className="col-md-4 col-sm-12 mb-4">
                    <GatsbyImage image={image2.image.gatsbyImageData} />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <GatsbyImage image={image3.image.gatsbyImageData} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h4 className="dlab-title mt-2">Novel Diagnostics</h4>
                    <p className="text-justify">
                      The MammaPrint® test analyzes the 70 most important genes
                      associated with breast cancer recurrence. Results are
                      typically available in 6 days or less, MammaPrint enables
                      quicker, more informed decisions on pre- and
                      post-operative treatment and can easily be integrated into
                      diagnostic workups. <sup>1</sup>
                    </p>
                    <hr />
                    <p className="text-justify" style={{ fontSize: "12px" }}>
                      1 Following the 2016 Publication of MINDACT, the
                      2017 ASCO guidelines were updated to indicate favorable
                      results in patients with 1-3 positive lymph nodes.
                      MammaPrint’s 510(k) US FDA clearance includes breast
                      cancer patients with Stage 1 or Stage II disease, with
                      tumor size ≤ 5.0 cm and lymph node negative.
                    </p>
                    {/* <p className="text-justify mt-3">
                      Another product is the Tumor M2-PK Stool Test, a highly
                      sensitive and specific metabolic biomarker for colorectal
                      cancer screening, which is independent of "Faecal Occult
                      Blood" (FOB).
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Diagnostics
