import React from "react"
import Header from "../../layouts/header"
import Footer from "./FooterCream"
import FloatingBtn from "./floatingBtn"
import ScrollToTop from "../scrollToTop"

import "../../css/plugins.css"
import "../../css/style.css"
import "../../css/skin/skin-1.css"
import "../../css/templete.min.css"

const Layout = ({ children }) => {
  return (
    <>
      <div className="skin-1 index">
        <Header />
        <FloatingBtn />
        {children}
        <ScrollToTop className="icon-up" />
        <Footer hideContactInfo />
      </div>
    </>
  )
}

export default Layout
